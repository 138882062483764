import React, { FunctionComponent } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { ISiteQuery } from '../types/site'
import { IEdgesQuery } from '../types/queries'
import { ISitePage } from '../types/page'
import Nav from '../components/nav'

import '../styles/header.scss'

export interface IHeaderQuery {
  site: ISiteQuery
  categories: IEdgesQuery<ISitePage>
}

const Header: FunctionComponent = () => {
  const {
    site,
  } = useStaticQuery<IHeaderQuery>(graphql`
    query HeaderQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <header className='header'>
      <h1>
        <Link
          to='/'
        >
          {site.siteMetadata.title}
        </Link>
      </h1>

      <Nav />
    </header>
  )
}

export default Header
