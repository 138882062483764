import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'

export const ACTIVE_NAV_ITEM = 'active-nav-item'

const pages = [
  {
    text: 'Drawings',
    path: '/',
  },
  {
    text: 'About',
    path: '/about/',
  },
]

const Nav: FunctionComponent = () => {
  return (
    <>
      {pages.map(({ text, path }) => (
        <span
          className='nav-item'
          key={text}
        >
          <Link
            to={path}
            activeClassName={ACTIVE_NAV_ITEM}
          >
            {text}
          </Link>
        </span>
      ))}
    </>
  )
}

export default Nav
