import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { ISiteQuery } from '../types/site'

export interface IHeadProps {
  title: string
  description?: string
  meta?: any[]
}

const Head: FunctionComponent<IHeadProps> = ({
  title,
  description,
  meta,
}) => {
  const { site } = useStaticQuery<{site: ISiteQuery}>(graphql`
    query SiteMetaQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  const metaDescription = description ?? site.siteMetadata.description

  return (
    <Helmet
      title={title || site.siteMetadata.title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
      ].concat(meta ?? [])}
    />
  )
}

export default Head
