import React, { FunctionComponent } from 'react'
import Header from './header'

import '../styles/style.scss'
import '../styles/desktop.scss'

interface ILayoutProps {
  className?: string
}

const Layout: FunctionComponent<ILayoutProps> = ({
  className,
  children,
}) => {
  return (
    <div
      className={`page ${className ?? ''}`}
    >
      <Header/>

      <main
        tabIndex={-1}
      >
        {children}
      </main>
    </div>
  )
}

export default Layout
